<div class="ct-error-page">
    <div class="ct-exception-panel">
        <h1><i class="material-icons">&#xE88F;</i>Internal Server Error</h1>
        <div class="ct-exception-details">
            Sorry, something went wrong.
        </div>
        <a mat-button routerLink="/" class="ct-btn ct-primary-button">
            <mat-icon>chevron_left</mat-icon>
            Back
        </a>
    </div>
</div>